<template lang="pug">
ClientOnly
  .image-bcg(
    :key="imageUrl"
    :style="{ '--image-url': imageUrl, '--cover-position-y': coverPositionY }"
    loading="lazy"
    role="img" 
    :aria-label="imageAlt"
  )
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { TImage } from "@/models/image";

interface IProps {
  image?: TImage | null;
  coverPositionY?: string;
}

const props = withDefaults(defineProps<IProps>(), {
  image: null,
  coverPositionY: "center",
});

const isLocalhost = computed(() => {
  // return false;
  
  if (typeof window !== 'undefined') {
    const { host } = window.location;

    return host.includes("localhost");
  }

  return false;
})

const imageAlt = computed(() => {
  return props.image?.alternativeText || "";
})

const imageUrl = computed(() => {
  if (isLocalhost.value) {
    return props.image?.hash && props.image?.ext
      ? `url('http://s3.nationbetter.uk.s3.eu-west-2.amazonaws.com/${props.image.hash}${props.image.ext}')`
      : "none";
  }

  const url = props.image?.url ||  "";
  
  const formats = props.image?.formats;
  const formatUrl =
    formats?.large?.url ||
    formats?.medium?.url ||
    formats?.small?.url ||
    formats?.thumbnail?.url ||
    "";

  const resultUrl = url ? `url('${url}')` : `url('${formatUrl}')`;

  return resultUrl ? resultUrl : "none";
});
</script>

<style lang="sass" scoped>
.image-bcg
  height: 100%
  flex-shrink: 0
  border-radius: 16px
  background-image: var(--image-url)
  background-position: center
  background-position-y: var(--cover-position-y)
  background-size: cover
  background-repeat: no-repeat
  position: relative
</style>